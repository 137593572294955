const translations: Record<string, Record<string, string>> = {
  en: {
    welcome: "Welcome",
    welcome_note:
      "Welcome! We are excited to have you here. Get ready to begin your assessment!",
    gathering_info: "Gathering Info...",
    something_went_wrong: "Something went wrong!",
    error_code: "Error Code",
    support: "Support",
    page_not_found: "Page not found!",
    unrecognized_error: "Unrecognized Error",
    no_data_found: "No Data Found!",
    name: "Name",
    total_duration: "Total Duration",
    start_assessment: "Start Assessment",
    consent_agreement: "Consent Agreement",
    instructions: "Instructions",
    first_consent_a:
      "We request to do a practise session first if not done already.",
    first_consent_b:
      "Moving forward, you will be asked questions which are video / audio based, MCQs or descriptive. These are all time-based therefore please make sure you complete the answers within the given time.",
    first_consent_c:
      "The interview questions will appear in full screen mode,please do not exit full screen mode or you would be exited from the interview as well.",
    first_consent_d:
      "  You may review your answers in the end but cannot re-submit them.",
    second_consent:
      "By clicking Agree, you confirm that you are ready to start the assessment. Ensure you have a stable internet connection.",
    Data_retention_consent: "Data Retention Consent:",
    second_consent_a: "AI & Data Consent",
    second_consent_b:
      "I consent to the retention of my personal data for 6 months after the recruitment process for future job opportunities. I understand I can request deletion of my data at any time.",
    second_consent_c:
      "I consent to the sharing of my personal data with third-party service providers involved in the recruitment process. I understand my data will be securely handled according to data protection laws.",
    second_consent_d:
      "I consent to AI-driven analysis of my application data, including video, audio, and written assessments, as part of the recruitment process. I understand that I can request a human review of any AI-generated decisions.",
    next: "Next",
    continue: "Continue",
    cancel: "Cancel",
    agree: "Agreed & Start Interview",
    no_video_available: "No video available!",
    go_home: "GO Home",
    all_permissions_required:
      "All permissions are required. Without these, you cannot continue your assessment.",
    screen_sharing: "Screen Sharing",
    screen_sharing_description:
      "Required for employees to share their screen during assessments.",
    audio: "Audio",
    audio_description:
      "Needed for voice-based instructions and verbal responses during the test.",
    video: "Video",
    video_description:
      "Used for identity verification and monitoring during the assessment.",
    full_screen: "Full Screen",
    full_screen_description:
      "Ensures the test environment remains distraction-free by preventing switching.",
    internet: "Internet",
    internet_description:
      "Verifies a stable connection to avoid interruptions during the test.",
    back: "Back",
    grant_permission: "Grant Permission",
    starting: "Starting...",
    assessment_started: "Assessment started!",
    assessment_start_error: "Error starting assessment.",
    assessment_type: "Assessment Type",
    submit_and_next: "Submit and Next",
    submit: "Submit",
    start: "Start",
    stop: "Stop",
    retake: "Retake",
    submitting: "Submitting...",
    you_are_not_following_the_proper: "You are not following the proper steps!",
    congratulations: "Congratulations",
    you_have_successfully_submitted:
      "You have successfully completed the Assessment.",
    answer_time: "Answer TIme",
    empty_answer: "You have left an empty answer!",
    progress: "Progress",
    total: "Total",
    answered: "Recorded",
    empty: "Empty",
    success_rate: "Success Rate",
    submission_rate: "submission rate",
    error_rate: "Error Rate",
    go_to_initial_page: "Go to Initial Page!",
    check_your_email_to_start_assessment:
      "Check You Email to Start an Assessment!",
    feedback_submitted: "Feedback Submitted! Thanks",
    give_your_feedback: "Give Your Feedback",
    feedback: "Feedback",
    we_will_get_back_to_you: "We will get back to you within one week. Thanks!",
    recorder: "Recorded",
    find: "Find",
    developers: "Developers",
    who_create: "Who Create",
    top_notch_code: "Top-Notch Code",
    with_ai: "with AI",
    description:
      "Discover the power of AI-assisted coding and access affordable development services.",
    description1:
      "Hire expert AI-powered developers to take your projects to the next level.",
    hire_developer: "Hire Developer",
    find_jobs: "Find Jobs",
    assessment_is_going_to_start: "The Assessment is going to start!",
    answer_submitted_successfully: "Answer submitted successfully!",
    you_have_left_an_empty_answer: "You have left an empty answer.",
    question_statement: "Question Statement",
    powered_by: "Powered By",
    start_practice: "Practice",
    loading: "Loading...",
    run_code: "Run Code",
    click_to_run_code: "Click Run Code to see the output here",
    unable_to_run_code: "Unable to run code",
    give_some_code_run_error: "Give some Code to run!",
    an_error_occurred: "An error occurred. Please try again.",
  },
  ur: {
    welcome: "خوش آمدید",
    welcome_note:
      "خوش آمدید! ہمیں خوشی ہے کہ آپ یہاں ہیں۔ اپنا جائزہ شروع کرنے کے لیے تیار ہو جائیں!",
    gathering_info: "معلومات اکٹھی کی جا رہی ہیں...",
    something_went_wrong: "کچھ غلط ہو گیا!",
    error_code: "خرابی کا کوڈ",
    support: "مدد",
    page_not_found: "صفحہ نہیں ملا!",
    unrecognized_error: "غیر متوقع خرابی",
    no_data_found: "کوئی ڈیٹا نہیں ملا!",
    name: "نام",
    total_duration: "کل دورانیہ",
    start_assessment: "تشخیص شروع کریں",
    consent_agreement: "رضامندی کا معاہدہ",
    instructions: "ہدایات",
    Data_retention_consent: "ڈیٹا برقرار رکھنے کی رضامندی:",
    first_consent_a:
      "ہم درخواست کرتے ہیں کہ پہلے پریکٹس سیشن کریں اگر پہلے ہی نہیں کیا گیا ہے۔",
    first_consent_b:
      "آگے بڑھتے ہوئے، آپ سے سوالات پوچھے جائیں گے جو ویڈیو/آڈیو پر مبنی، MCQs یا وضاحتی ہوں۔ یہ سب وقت پر مبنی ہیں اس لیے براہ کرم یقینی بنائیں کہ آپ جوابات کو مقررہ وقت کے اندر مکمل کر لیں۔",
    first_consent_c:
      "انٹرویو کے سوالات فل سکرین موڈ میں ظاہر ہوں گے، براہ کرم فل سکرین موڈ سے باہر نہ نکلیں ورنہ آپ کو انٹرویو سے بھی باہر کر دیا جائے گا۔",
    first_consent_d:
      "آپ آخر میں اپنے جوابات کا جائزہ لے سکتے ہیں لیکن انہیں دوبارہ جمع نہیں کر سکتے۔",

    second_consent_a: "AI اور ڈیٹا کی رضامندی۔",
    second_consent_b:
      "میں مستقبل میں ملازمت کے مواقع کے لیے بھرتی کے عمل کے بعد 6 ماہ تک اپنے ذاتی ڈیٹا کو برقرار رکھنے کی رضامندی دیتا ہوں۔ میں سمجھتا ہوں کہ میں کسی بھی وقت اپنے ڈیٹا کو حذف کرنے کی درخواست کر سکتا ہوں۔",
    second_consent_c:
      "میں بھرتی کے عمل میں شامل فریق ثالث سروس فراہم کنندگان کے ساتھ اپنے ذاتی ڈیٹا کے اشتراک پر رضامندی دیتا ہوں۔ میں سمجھتا ہوں کہ میرے ڈیٹا کو ڈیٹا کے تحفظ کے قوانین کے مطابق محفوظ طریقے سے ہینڈل کیا جائے گا۔",
    second_consent_d:
      "میں بھرتی کے عمل کے حصے کے طور پر، ویڈیو، آڈیو، اور تحریری جائزوں سمیت اپنے درخواست کے ڈیٹا کے AI سے چلنے والے تجزیے کے لیے رضامند ہوں۔ میں سمجھتا ہوں کہ میں AI سے تیار کردہ کسی بھی فیصلے پر انسانی نظرثانی کی درخواست کر سکتا ہوں۔",

    continue: "جاری رکھیں",
    first_consent:
      "تشخیص شروع کرنے سے پہلے، براہ کرم ہدایات غور سے پڑھیں۔ آپ کو مکمل کرنے کے لیے محدود وقت ملے گا۔",
    second_consent:
      "متفق پر کلک کر کے، آپ تصدیق کرتے ہیں کہ آپ تشخیص شروع کرنے کے لیے تیار ہیں۔ یقینی بنائیں کہ آپ کے پاس مستحکم انٹرنیٹ کنکشن ہے۔",
    next: "اگلا",
    cancel: "منسوخ کریں",
    agree: "متفق ہوں",
    no_video_available: "کوئی ویڈیو دستیاب نہیں!",
    go_home: "گھر جائیں",
    all_permissions_required:
      "تمام اجازتیں ضروری ہیں۔ اس کے بغیر آپ اپنی تشخیص جاری نہیں رکھ سکتے۔",
    screen_sharing: "اسکرین شیئرنگ",
    screen_sharing_description:
      "ملازمین کے لیے تشخیص کے دوران اپنی اسکرین شیئر کرنے کے لیے ضروری ہے۔",
    audio: "آڈیو",
    audio_description:
      "ٹیسٹ کے دوران صوتی ہدایات اور زبانی جوابات کے لیے ضروری ہے۔",
    video: "ویڈیو",
    video_description:
      "شناخت کی تصدیق اور تشخیص کے دوران نگرانی کے لیے استعمال کیا جاتا ہے۔",
    full_screen: "مکمل اسکرین",
    full_screen_description:
      "ٹیسٹ کے ماحول کو خلل سے پاک رکھنے کے لیے سوئچنگ کو روکتا ہے۔",
    internet: "انٹرنیٹ",
    internet_description:
      "ٹیسٹ کے دوران رکاوٹوں سے بچنے کے لیے مستحکم کنکشن کی تصدیق کرتا ہے۔",
    back: "پیچھے",
    grant_permission: "اجازت دیں",
    starting: "شروع ہو رہا ہے...",
    assessment_started: "تشخیص شروع ہو گئی!",
    assessment_start_error: "تشخیص شروع کرنے میں خرابی۔",
    assessment_type: "تشخیص کی قسم",
    submit_and_next: "جمع کروائیں اور اگلا",
    submit: "جمع کروائیں۔",
    start: "شروع کریں",
    stop: "روکیں",
    retake: "دوبارہ لیں",
    submitting: "جمع کر رہا ہے...",
    you_are_not_following_the_proper: "آپ درست اقدامات پر عمل نہیں کر رہے ہیں!",
    congratulations: "مبارک ہو",
    you_have_successfully_submitted: "آپ نے کامیابی سے تشخیص مکمل کر لی ہے۔",
    answer_time: "جواب کا وقت",
    empty_answer: "آپ نے خالی جواب چھوڑ دیا ہے!",
    progress: "پیش رفت",
    total: "کل",
    answered: "ریکارڈ شدہ",
    empty: "خالی",
    success_rate: "کامیابی کی شرح",
    submission_rate: "جمع کرانے کی شرح",
    error_rate: "غلطی کی شرح",
    go_to_initial_page: "ابتدائی صفحے پر جائیں!",
    check_your_email_to_start_assessment:
      "تشخیص شروع کرنے کے لیے اپنا ای میل چیک کریں!",
    feedback_submitted: "تاثرات جمع کرائے گئے! شکریہ",
    give_your_feedback: "اپنی رائے دیں",
    feedback: "رائے",
    we_will_get_back_to_you: "ہم ایک ہفتے کے اندر آپ سے رابطہ کریں گے۔ شکریہ!",
    recorder: "ریکارڈ شدہ",
    find: "تلاش کریں",
    developers: "ڈویلپرز",
    who_create: "جو بناتے ہیں",
    top_notch_code: "اعلی معیار کا کوڈ",
    with_ai: "اے آئی کے ساتھ",
    description:
      "AI کی مدد سے کوڈنگ کی طاقت دریافت کریں اور سستی ترقیاتی خدمات تک رسائی حاصل کریں۔",
    description1:
      "ماہرانہ AI سے چلنے والے ڈویلپرز کی خدمات حاصل کریں تاکہ آپ کے پروجیکٹس کو اگلے درجے پر لے جایا جا سکے۔",
    hire_developer: "ڈویلپر کی خدمات حاصل کریں",
    find_jobs: "نوکریاں تلاش کریں",
    assessment_is_going_to_start: "تشخیص شروع ہونے والی ہے!",
    answer_submitted_successfully: "جواب کامیابی سے جمع کرایا گیا!",
    you_have_left_an_empty_answer: "آپ نے ایک خالی جواب چھوڑ دیا ہے۔",
    question_statement: "سوال کا بیان",
    powered_by: "کی طرف سے طاقت",
    start_practice: "مشق شروع کریں",
    loading: "لوڈ ہو رہا ہے...",
    run_code: "کوڈ چلائیں",
    click_to_run_code: "یہاں نتیجہ دیکھنے کے لیے 'کوڈ چلائیں' پر کلک کریں",
    unable_to_run_code: "کوڈ چلانے سے قاصر",
    give_some_code_run_error: "کوڈ فراہم کریں تاکہ اسے چلایا جا سکے!",
    an_error_occurred: "ایک خرابی پیش آگئی۔ براہ کرم دوبارہ کوشش کریں۔",
  },
};

export default translations;

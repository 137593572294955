import axios from "axios";
import * as Sentry from "@sentry/react";
// Base URL (change it to your API's base URL)
const BASE_URL = import.meta.env.VITE_BASE_URL;
// const BASE_URL = "https://api.dev.airec.io/";
// Function to extract token from URL only once
const getTokenFromUrl = () => {
  const match = location.pathname.match(/^\/assessment_id\/([^/]+)$/);
  return match ? match[1] : null;
};

// Store token in localStorage if found in the URL
const initializeToken = () => {
  localStorage.removeItem("authToken");
  const token = getTokenFromUrl();
  if (token) {
    localStorage.setItem("authToken", token);
  }
};

// Initialize token when the script runs
initializeToken();

// Create Axios Instance
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request Interceptor (Adding Auth Token Dynamically)
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("authToken"); // Retrieve stored token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor (Handling Errors Globally)
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorData = error?.response?.data || error.message;
    Sentry.captureException(error);
    console.error("API Error:", errorData);
    return Promise.reject(error);
  }
);

export default api;

import { createContext, useContext, useEffect, useState } from "react";
import { AssessmentData, InterviewQuestion } from "../types/apiType";
import { AxiosError } from "axios";
import ApiService from "../api/apiService";
import _autoInterview from "../data/automaticInterview.json";
import { useLanguage } from "./LanguageContext";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import _api from "../api/api";
import * as Sentry from "@sentry/react";
import Spinner from "../components/Spinner";
// Define types
interface ApiContextType {
  assessmentObject: AssessmentData | null;
  loading: boolean;
  error: AxiosError | null;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  interviewQuestionsLength: number;
  isInitialIndex: boolean;
  isLastIndex: boolean;
  handleQuestionChange: (
    questionId: string,
    field: keyof InterviewQuestion,
    value: any
  ) => void;
  updateConsents: () => void;
}

// Create Context
const ApiContext = createContext<ApiContextType | undefined>(undefined);

// Provider Component
export function ApiProvider({ children }: { children: React.ReactNode }) {
  const { t } = useLanguage();
  const location = useLocation();
  // Extract interviewId from the URL path "/assessment_id/:id"
  const match = location.pathname.match(/^\/assessment_id\/([^/]+)$/);
  const interviewId = match ? match[1] : null;

  const [assessmentObject, setAssessmentObject] =
    useState<AssessmentData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  useEffect(() => {
    if (interviewId) {
      fetchAssessment();
    }
  }, []);

  const fetchAssessment = async () => {
    setLoading(true);
    try {
      const response = await ApiService.getAssessment();
      // Extract data correctly
      const rawData = response.data.data as AssessmentData;

      // Add new keys to each interview question and append status automatically
      const updatedQuestions = !rawData.assessment_type
        ? rawData.interview_questions.map((question) => ({
            ...question,
            title_en: question.title,
            title_ur: "",
            answer: "",
            statusCode: null,
            error: null,
            reading_time: question.reading_time ?? 60,
            time_duration: question.time_duration ?? 60,
            answers:
              question.question_type === "mcq"
                ? question.answers.map((answer) => ({
                    en: String(answer),
                    ur: "",
                  }))
                : [],
          }))
        : rawData.interview_questions.map((question) => ({
            ...question,
            answer: "",
            statusCode: null,
            error: null,
            reading_time: question.reading_time ?? 60,
            time_duration: question.time_duration ?? 60,
          }));

      // Update state with transformed data
      setAssessmentObject({
        ...rawData,
        interview_questions: updatedQuestions,
        consent: false,
      });
    } catch (err) {
      Sentry.captureException("Error fetching Assessment:");
      console.error("Error fetching Assessment:", err);

      setError(err as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  const interviewQuestionsLength = assessmentObject?.interview_questions
    ? assessmentObject?.interview_questions.length
    : 0;
  const isInitialIndex = currentIndex < 1;
  const isLastIndex = currentIndex === interviewQuestionsLength - 1;

  function handleQuestionChange(
    questionId: string,
    field: keyof InterviewQuestion,
    value: number | string
  ): void {
    setAssessmentObject((prevData) => {
      if (!prevData) return prevData; // Ensure prevData is not null

      return {
        ...prevData,
        interview_questions: prevData.interview_questions.map((question) =>
          question._id === questionId
            ? { ...question, [field]: value }
            : question
        ),
      };
    });
  }

  // Function to update error for a specific question
  const updateConsents = () => {
    setAssessmentObject((prevData) => {
      if (!prevData) return prevData; // Ensure prevData is not null

      return {
        ...prevData,
        consent: true,
      };
    });
  };

  return (
    <ApiContext.Provider
      value={{
        assessmentObject,
        loading,
        error,
        currentIndex,
        setCurrentIndex,
        interviewQuestionsLength,
        isInitialIndex,
        isLastIndex,
        handleQuestionChange,
        updateConsents,
      }}
    >
      <Navbar />
      {loading ? (
        <div className="flex flex-col items-center h-screen  justify-center ">
          <Spinner />
          <h1>{t("gathering_info")}</h1>
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center h-screen text-center">
          <span className="icon-[fluent-color--warning-16] text-7xl"></span>
          <h1>{error?.code || t("unrecognized_error")}</h1>
          <h1>{error?.message || t("something_went_wrong")}</h1>
        </div>
      ) : (
        children
      )}
      <Footer />
    </ApiContext.Provider>
  );
}

// Custom Hook to Use Context
export function useApi() {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
}

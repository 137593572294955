import { Link } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import AirecLogo from "../assets/w_logo.png";
export default function Footer() {
  const { t } = useLanguage();
  return (
    <footer className="footer bg-[#303743]   shadow-inner px-5  flex justify-between  items-center  h-12  fixed bottom-0 left-0 w-full z-50">
      <div className="flex flex-row-reverse w-full flex-wrap items-center justify-between">
        <div className="flex items-center gap-2 text-xl font-bold"></div>
        <aside className="flex gap-2 items-center">
          <small className="hidden sm:block">{t("powered_by")}</small>
          <img src={AirecLogo} className="w-28" />
        </aside>
        <div className="invisible">
          <Link to="/support">
            <button className="btn btn-outline btn-info btn-md max-sm:btn-square">
              <span className="text-xl icon-[fluent--person-support-24-filled]"></span>
              <span className="max-sm:hidden">{t("support")}</span>
            </button>
          </Link>
        </div>
      </div>
    </footer>
  );
}

import React, { createContext, useContext, useEffect, useRef, useState, ReactNode } from "react";
import { PermissionContextType } from "../types/permissionsType";
import { showNotification } from "../utils/functions";

const PermissionContext = createContext<PermissionContextType | null>(null);

interface PermissionProviderProps {
  children: ReactNode;
}

export const PermissionProvider: React.FC<PermissionProviderProps> = ({ children }) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(!!document.fullscreenElement);
  const [isScreenSharing, setIsScreenSharing] = useState<boolean>(false);
  const [isAudioAllowed, setIsAudioAllowed] = useState<boolean>(false);
  const [isVideoAllowed, setIsVideoAllowed] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const screenStreamRef = useRef<MediaStream | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const requestFullScreen = () => {
    const docElement = document.documentElement;
    if (docElement.requestFullscreen) docElement.requestFullscreen();
    else if ((docElement as any).mozRequestFullScreen) (docElement as any).mozRequestFullScreen();
    else if ((docElement as any).webkitRequestFullscreen) (docElement as any).webkitRequestFullscreen();
    else if ((docElement as any).msRequestFullscreen) (docElement as any).msRequestFullscreen();
  };

  const exitFullScreen = () => {
    if (document && document.exitFullscreen) document?.exitFullscreen();
    else if ((document as any).mozCancelFullScreen) (document as any).mozCancelFullScreen();
    else if ((document as any).webkitExitFullscreen) (document as any).webkitExitFullscreen();
    else if ((document as any).msExitFullscreen) (document as any).msExitFullscreen();
  };

  const requestScreenSharing = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { displaySurface: "monitor" },
      });
      screenStreamRef.current = stream;
      setIsScreenSharing(true);
      stream.getVideoTracks()[0].onended = () => stopScreenSharing();
    } catch (error) {
      console.error("Screen sharing permission denied:", error);
    }
  };

  const stopScreenSharing = () => {
    screenStreamRef.current?.getTracks().forEach(track => track.stop());
    screenStreamRef.current = null;
    setIsScreenSharing(false);
  };

  const requestAudioPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      setIsAudioAllowed(true);
    } catch (error) {
      console.error("Microphone permission denied:", error);
    }
  };

  const requestVideoPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      mediaStreamRef.current = stream;
      setIsVideoAllowed(true);
    } catch (error) {
      console.error("Camera permission denied:", error);
    }
  };

  const stopMediaStreams = () => {
    mediaStreamRef.current?.getTracks().forEach(track => track.stop());
    mediaStreamRef.current = null;
    setIsAudioAllowed(false);
    setIsVideoAllowed(false);
  };

  useEffect(() => {
    return () => {
      stopScreenSharing();
      stopMediaStreams();
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      const isNowFullScreen = !!document.fullscreenElement;
      setIsFullScreen(isNowFullScreen);

      if (!isNowFullScreen && screenStreamRef.current ) {
        showNotification("You have exited fullscreen mode.","error");
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <PermissionContext.Provider
      value={{
        isFullScreen,
        isScreenSharing,
        isAudioAllowed,
        isVideoAllowed,
        isOnline,
        requestFullScreen,
        exitFullScreen,
        requestScreenSharing,
        stopScreenSharing,
        requestAudioPermission,
        requestVideoPermission,
        stopMediaStreams,
        screenStreamRef,
        mediaStreamRef,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermission must be used within a PermissionProvider");
  }
  return context;
};

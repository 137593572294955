import { useNetworkCheck } from '../context/NetworkContext';

export default function ConnectivityBar() {
    const { isOnline } = useNetworkCheck();
  return (
    !isOnline && (
        <div
          className=" alert-soft alert-error my-2  flex items-center gap-2 justify-center py-1"
          role="alert"
        >
          <span className="icon-[material-symbols-light--portable-wifi-off] text-warning size-6"></span>
          <p>
            <span className="text-lg text-warning">Internet Alert : </span>Oops!
            No Internet Connection.
          </p>
        </div>
      )
  )
}

import { useEffect } from "react";
import { useLanguage } from "../context/LanguageContext";

const LanguageToggle = () => {
  const { language, setLanguage } = useLanguage();

  useEffect(() => {
    // Set direction based on the selected language
    document.documentElement.setAttribute(
      "dir",
      language === "ur" ? "rtl" : "ltr"
    );
  }, [language]);

  const toggleLanguage = () => {
    setLanguage(language === "en" ? "ur" : "en");
  };

  return (
    <>
      {/* Language Toggle Button */}
      <label className="swap ">
        <input
          type="checkbox"
          onChange={toggleLanguage}
          checked={language === "ur"}
        />
        {/* <span className="swap-off icon-[hugeicons--translation] size-7 text-white"></span>
        <span className="swap-on icon-[hugeicons--translation] text-info size-7"></span>
         */}

        <span className="swap-off btn btn-info btn-soft btn-outline font-medium">
          اردو
        </span>
        <span className="swap-on btn btn-info btn-soft btn-outline font-medium">
          English
        </span>
      </label>
    </>
  );
};

export default LanguageToggle;

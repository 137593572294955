import { Navigate } from "react-router-dom"

export default function Danger() {
  

  return (
    
    <Navigate to="/" replace />
  )
}

import { createContext, useContext, useEffect, useState } from "react";
import { AssessmentData, InterviewQuestion } from "../types/apiType";
import _autoInterview from "../data/automaticInterview.json";
import { useLanguage } from "./LanguageContext";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import _api from "../api/api";
import practiceData from "../data/automaticInterview.json";
import Spinner from "../components/Spinner";
// Define types
interface PracticeSessionContextType {
  assessmentObject: AssessmentData | null;
  loading: boolean;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  interviewQuestionsLength: number;
  isInitialIndex: boolean;
  isLastIndex: boolean;
  handleQuestionChange: (
    questionId: string,
    field: keyof InterviewQuestion,
    value: any
  ) => void;
  updatePracticeConsents: () => void;
  setisPracticeCompleted: () => void;
}

// Create Context
const PracticeSessionContext = createContext<
  PracticeSessionContextType | undefined
>(undefined);

// Provider Component
export function PracticeSessionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useLanguage();

  const [assessmentObject, setAssessmentObject] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const fetchAssessment = async () => {
    setLoading(true);
    setTimeout(() => {
      setAssessmentObject(practiceData);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchAssessment();
  }, []);

  const interviewQuestionsLength = assessmentObject?.interview_questions
    ? assessmentObject?.interview_questions.length
    : 0;
  const isInitialIndex = currentIndex < 1;
  const isLastIndex = currentIndex === interviewQuestionsLength - 1;

  function handleQuestionChange(
    questionId: string,
    field: keyof InterviewQuestion,
    value: number | string
  ): void {
    setAssessmentObject((prevData: AssessmentData) => {
      if (!prevData) return prevData; // Ensure prevData is not null

      return {
        ...prevData,
        interview_questions: prevData.interview_questions.map((question) =>
          question._id === questionId
            ? { ...question, [field]: value }
            : question
        ),
      };
    });
  }

  // Function to update error for a specific question
  const updatePracticeConsents = () => {
    setAssessmentObject((prevData: AssessmentData) => {
      if (!prevData) return prevData; // Ensure prevData is not null

      return {
        ...prevData,
        consent: true,
      };
    });
  };

  // setisPracticeCompleted
  const setisPracticeCompleted = () => {
    setAssessmentObject((prevData: AssessmentData) => {
      if (!prevData) return prevData; // Ensure prevData is not null

      return {
        ...prevData,
        isCompleted: true,
      };
    });
  };

  return (
    <PracticeSessionContext.Provider
      value={{
        assessmentObject,
        loading,
        currentIndex,
        setCurrentIndex,
        interviewQuestionsLength,
        isInitialIndex,
        isLastIndex,
        handleQuestionChange,
        updatePracticeConsents,
        setisPracticeCompleted,
      }}
    >
      <Navbar />
      {loading ? (
        <div className="flex flex-col items-center h-screen  justify-center ">
          <Spinner />
          <h1>{t("gathering_info")}</h1>
        </div>
      ) : (
        children
      )}
      <Footer />
    </PracticeSessionContext.Provider>
  );
}

// Custom Hook to Use Context
export function usePractice() {
  const context = useContext(PracticeSessionContext);
  if (!context) {
    throw new Error("usePractice must be used within an ApiProvider");
  }
  return context;
}

export default PracticeSessionProvider;

import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";

interface ScreenShots {
  src: string;
  width: number;
  height: number;
}
// Define types for context values
interface ScreenshotContextType {
  isCapturing: boolean;
  setIsCapturing: React.Dispatch<React.SetStateAction<boolean>>;
  screenshots: ScreenShots[]; // Ensure screenshots match Image type from react-grid-gallery
  addScreenshot: (screenshot: string,full_screen:boolean) => void; // Accepts a string (base64 image URL)
  clearScreenshots: () => void;
}

// Create context with default undefined to enforce usage inside provider
const ScreenshotContext = createContext<ScreenshotContextType | undefined>(undefined);

interface ScreenshotProviderProps {
  children: ReactNode;
}

export const ScreenshotProvider: React.FC<ScreenshotProviderProps> = ({ children }) => {
  const [isCapturing, setIsCapturing] = useState<boolean>(false);
  const [screenshots, setScreenshots] = useState<ScreenShots[]>([]);

  const addScreenshot = useCallback((screenshot: string) => {
    setScreenshots((prev) => [
      ...prev,
      { 
        src: screenshot, 
        width: 1920,  // Default width (adjust as needed)
        height: 1080, // Default height (adjust as needed)
      }
    ]);
  }, []);

  const clearScreenshots = () => {
    setScreenshots([]);
  };

  return (
    <ScreenshotContext.Provider value={{ isCapturing, setIsCapturing, screenshots, addScreenshot, clearScreenshots }}>
      {children}
    </ScreenshotContext.Provider>
  );
};

// Custom hook to use the Screenshot context
export const useScreenshot = (): ScreenshotContextType => {
  const context = useContext(ScreenshotContext);
  if (!context) {
    throw new Error("useScreenshot must be used within a ScreenshotProvider");
  }
  return context;
};

export default ScreenshotProvider;

import { useTheme } from '../context/ThemeContext'
import { InfinitySpin } from 'react-loader-spinner'
import { color } from '../color/color'

export default function Spinner() {
    const {theme} =useTheme()
  return (
    <InfinitySpin color={theme === 'dark' ? color.white :color.black} />
  )
}

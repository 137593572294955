import { useRef, useState } from "react";
import { Editor, Monaco } from "@monaco-editor/react";
import { CODE_SNIPPETS } from "../utils/constants";
import LanguageSelector from "./LanguageSelector";
import Output from "./Output";

const CodeEditor = ({
  value,
  setValue,
  disabled,
  showSelector = true,
}: {
  value: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  showSelector?: boolean;
}) => {
  const editorRef = useRef<Monaco | null>(null);
  const [language, setLanguage] = useState<string>("javascript");

  const onMount = (editor: any) => {
    editorRef.current = editor;
    editor.focus();
  };

  const onSelect = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);
    if (setValue) {
      setValue(CODE_SNIPPETS[selectedLanguage]);
    }
  };

  return (
    <div>
      <div className="space-y-4">
        <div className="w-full">
          {showSelector && (
            <LanguageSelector
              disabled={disabled}
              language={language}
              onSelect={onSelect}
            />
          )}

          <Editor
            className="select-none"
            options={{
              readOnly: disabled,
              minimap: {
                enabled: false,
              },
            }}
            height="50vh"
            theme="vs-dark"
            language={language}
            defaultValue={CODE_SNIPPETS[language]}
            onMount={onMount}
            value={value}
            onChange={(val) => {
              if (setValue) {
                setValue(val || "");
              }
            }}
          />
        </div>
        {showSelector && <Output editorRef={editorRef} language={language} />}
      </div>
    </div>
  );
};

export default CodeEditor;

import api from "./api";

// Example API service functions
const ApiService = {
  // GET Request
  getAssessment: () => api.get("/interview-detail"),
   
  //Submit Answer
  submitAnswer: (data: { question_id?: string; answer_url?: string ,interview_type?:string,application_id?:string}) => api.post("/interview/submit-answer", data),
   
  //Feedback Request
  postFeedback: (data: { remark: string; rating: number }) => api.post("/interview/feedback", data),

  // POST Request
  createUser: (data: { name: string; email: string }) => api.post("/users", data),

  // PUT Request
  updateUser: (id: string, data: { name: string; email: string }) =>
    api.put(`/users/${id}`, data),

  // DELETE Request
  deleteUser: (id: string) => api.delete(`/users/${id}`),
};

export default ApiService;

import { useEffect, lazy, Suspense } from "react";
import { useLocation, Routes, Route, Navigate } from "react-router-dom";
import ConnectivityBar from "./components/ConnectivityBar";
import { PermissionProvider } from "./context/PermissionsContext";
import { LanguageProvider } from "./context/LanguageContext";
import { NetworkProvider } from "./context/NetworkContext";
import { ApiProvider } from "./context/ApiContext";
import { IStaticMethods } from "flyonui/flyonui";
import Main from "./pages/Main";
import { ScreenshotProvider } from "./context/ScreenshotContext";
import Gallery from "./pages/Gallery";
import Practice from "./pages/Practice";
import PracticeSessionProvider from "./context/PracticeSessionContext";
import { ThemeProvider } from "./context/ThemeContext";

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

// Lazy-loaded pages
const Home = lazy(() => import("./pages/Home"));
const Permissions = lazy(() => import("./pages/Permissions"));
const Support = lazy(() => import("./pages/Support"));
const Assessment = lazy(() => import("./pages/Assessment"));
const Overview = lazy(() => import("./pages/Overview"));

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      await import("flyonui/flyonui");
      window.HSStaticMethods.autoInit();
    })();
  }, [pathname]);

  return (
    <ThemeProvider>
    <LanguageProvider>
      <NetworkProvider>
        <ApiProvider>
          <PracticeSessionProvider>
            <PermissionProvider>
              <ScreenshotProvider>
                <div className="flex flex-col h-screen">
                  <div className="flex-grow  mt-13 pb-13 bg-base-200/60">
                    <ConnectivityBar />
                    <Suspense fallback={null}>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/assessment_id/:id" element={<Main />} />
                        <Route path="/started" element={<Assessment />} />
                        <Route path="/permissions" element={<Permissions />} />
                        <Route path="/support" element={<Support />} />
                        <Route path="/overview" element={<Overview />} />
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/practice" element={<Practice />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    </Suspense>
                  </div>
                </div>
              </ScreenshotProvider>
            </PermissionProvider>
          </PracticeSessionProvider>
        </ApiProvider>
      </NetworkProvider>
      </LanguageProvider>
      </ThemeProvider>
  );
}

export default App;

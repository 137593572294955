import "react-quill/dist/quill.snow.css"; // Import styles
import CodeEditor from "../CodeEditor";

export default function CodingAnswer({
  setValue,
  value,
  disabled,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  disabled: boolean;
}) {
  return <CodeEditor disabled={disabled} value={value} setValue={setValue} />;
}

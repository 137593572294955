import React, { useRef, useEffect, useState, useCallback } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useLanguage } from "../../context/LanguageContext";
import { showNotification } from "../../utils/functions";
import * as Sentry from "@sentry/react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../context/ApiContext";
import ApiService from "../../api/apiService";
import { usePractice } from "../../context/PracticeSessionContext";

interface AudioVideoAnswerProps {
  disabled?: boolean;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  timeLeft?: number;
  setIsRecord?: React.Dispatch<React.SetStateAction<boolean>>;
  question?: any;
  timerRef: any;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isPractice?: boolean;
}

const AudioVideoAnswer: React.FC<AudioVideoAnswerProps> = ({
  disabled,
  setValue,
  timeLeft,
  setIsRecord,
  question,
  timerRef,
  setIsLoading,
  isLoading,
  isPractice
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const {
    currentIndex,
    isLastIndex,
    setCurrentIndex,
    assessmentObject,
    handleQuestionChange,
  } = useApi();
  
  const {
    currentIndex: PracticeCurrentIndex,
    isLastIndex: PracticeIsLastIndex,
    setCurrentIndex: PracticeSetCurrentIndex,
    handleQuestionChange: PracticeHandleQuestionChange,
    setisPracticeCompleted
  } = usePractice();

  const [enableVideo, setEnableVideo] = useState(true);
  const [enableAudio, setEnableAudio] = useState(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isRetaking, setIsRetaking] = useState(false);
  const uploadCompletedRef = useRef(false);
  const valueRef = useRef("");
  const [hasRecordingStarted, setHasRecordingStarted] = useState(false);

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({
      video: enableVideo,
      audio: enableAudio,
      onStop: (blobUrl, blob) => {
        if (blob && !uploadCompletedRef.current) {
          handleFinalUpload(blobUrl);
        }
      },
    });

  // Handle automatic submission when time runs out
  useEffect(() => {
    if (timeLeft === 0) {
      if (status === "recording") {
        handleStopRecording();
      } else if (!hasRecordingStarted) {
        handleSubmit(null); // Submit null answer if recording never started
      }
    }
  }, [timeLeft, status, hasRecordingStarted]);

  const s3VideoAudioUpload = useCallback(async (blobUrl: string | null) => {
    if (!blobUrl || isPractice) return null; // Skip upload in practice mode
    
    try {
      setIsLoading(true);
      const response = await fetch(blobUrl);
      const blob = await response.blob();
      
      const file = new File([blob], `${Date.now()}.webm`, {
        type: blob.type,
      });

      const arrayBuffer = await file.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      const s3 = new S3Client({
        region: import.meta.env.VITE_AWS_REGION,
        credentials: {
          accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
          secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
        },
      });

      const params = {
        Bucket: import.meta.env.VITE_AWS_S3_BUCKET,
        Key: file.name,
        Body: uint8Array,
        ContentType: file.type,
      };

      await s3.send(new PutObjectCommand(params));

      return `https://${params.Bucket}.s3.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/${params.Key}`;
    } catch (error) {
      console.error("Error uploading to S3:", error);
      Sentry.captureException(`Error uploading to S3: ${error}`);
      showNotification(t("upload_failed"), "error");
      throw error;
    }
  }, [t, setIsLoading, isPractice]);

  const handleSubmit = useCallback(async (answer: string | null) => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    try {
      setIsLoading(true);
      
      if (isPractice) {
        // In practice mode, just simulate the submission
        await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate loading
        
        if (question) {
          PracticeHandleQuestionChange(question?._id, "answer", answer || "practice_answer");
          PracticeHandleQuestionChange(question?._id, "statusCode", 200);
          PracticeHandleQuestionChange(question?._id, "error", false);
        }
        
        if (answer) {
          showNotification(t("answer_submitted_successfully"), "success");
        } else {
          showNotification(t("you_have_left_an_empty_answer"), "warning");
        }
      } else {
        if (answer !== null) {
          answer = await s3VideoAudioUpload(answer);
        }
        
        if (question) {
          handleQuestionChange(question?._id, "answer", answer);
          handleQuestionChange(question?._id, "statusCode", 200);
          handleQuestionChange(question?._id, "error", false);
        }

        await ApiService.submitAnswer(
          assessmentObject?.assessment_type
            ? {
                question_id: question?._id,
                ...question,
                answer_url: answer,
                interview_type: assessmentObject.assessment_type,
                application_id: assessmentObject.application_id,
              }
            : {
                question_id: question?._id,
                answer_url: answer,
              }
        );

        if (answer) {
          showNotification(t("answer_submitted_successfully"), "success");
        } else {
          showNotification(t("you_have_left_an_empty_answer"), "warning");
        }
      }
    } catch (e) {
      showNotification(t("an_error_occurred"), "error");
      if (question?._id) {
        const handler = isPractice ? PracticeHandleQuestionChange : handleQuestionChange;
        handler(question?._id, "statusCode", 400);
        handler(question?._id, "error", true);
      }
    } finally {
      handleRetake();
      setHasRecordingStarted(false);
      setIsLoading(false);

      if (isPractice) {
        if (!PracticeIsLastIndex) {
          PracticeSetCurrentIndex(PracticeCurrentIndex + 1);
        } else {
          setisPracticeCompleted();
          navigate(-1);
        }
      } else {
        if (!isLastIndex) {
          setCurrentIndex(currentIndex + 1);
        } else {
          navigate("/overview", { replace: true });
        }
      }
    }
  }, [
    s3VideoAudioUpload,
    question,
    handleQuestionChange,
    PracticeHandleQuestionChange,
    assessmentObject,
    isLastIndex,
    currentIndex,
    PracticeCurrentIndex,
    PracticeIsLastIndex,
    PracticeSetCurrentIndex,
    navigate,
    setCurrentIndex,
    setValue,
    t,
    setIsLoading,
    timerRef,
    isPractice
  ]);

  const handleFinalUpload = useCallback(async (blobUrl: string) => {
    try {
      setValue(blobUrl);
      valueRef.current = blobUrl;
      uploadCompletedRef.current = true;
      
      if (!isPractice) {
        const s3Url = await s3VideoAudioUpload(blobUrl);
        
        if (s3Url) {
          setValue(s3Url);
          valueRef.current = s3Url;
        }
        
        await handleSubmit(s3Url || blobUrl);
      } else {
        await handleSubmit(blobUrl);
      }
    } catch (error) {
      console.error("Error handling final upload:", error);
      await handleSubmit(blobUrl);
    }
  }, [setValue, s3VideoAudioUpload, handleSubmit, isPractice]);
  useEffect(() => {
    async function getCameraStream() {
      if (!enableVideo && !enableAudio) return;

      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: enableVideo,
          audio: enableAudio,
        });

        setStream(mediaStream);

        if (videoRef.current && enableVideo) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.muted = true;
        }
      } catch (error) {
        showNotification(t("media_access_denied"), "error");
        Sentry.captureException(`Media access error: ${error}`);
      }
    }

    getCameraStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [isRetaking, enableVideo, enableAudio, t]);

  const handleStartRecording = () => {
    uploadCompletedRef.current = false;
    startRecording();
    setIsRecord?.(true);
    setHasRecordingStarted(true);
  };

  const handleStopRecording = () => {
    stopRecording();
    setIsRecord?.(false);
    
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const handleRetake = () => {
    uploadCompletedRef.current = false;
    clearBlobUrl();
    setValue("");
    valueRef.current = "";
    setIsRetaking((prev) => !prev);
    setHasRecordingStarted(false);
  };

  return (
    <div className="flex flex-col">
      <div className="bg-gray-800 shadow-lg p-6 w-full h-[32rem] relative">
        <div className="flex mb-4 justify-between">
          <div className="flex space-x-2 invisible">
            <label className="btn btn-circle swap swap-rotate">
              <input
                disabled={disabled}
                type="checkbox"
                checked={enableVideo}
                onChange={() => setEnableVideo(!enableVideo)}
              />
              <span className="icon-[circum--video-off] size-7 swap-off"></span>
              <span className="icon-[circum--video-on] size-7 swap-on"></span>
            </label>

            <label className="btn btn-circle swap swap-rotate">
              <input
                disabled={disabled}
                type="checkbox"
                checked={enableAudio}
                onChange={() => setEnableAudio(!enableAudio)}
              />
              <span className="icon-[ant-design--audio-muted-outlined] size-5 swap-off"></span>
              <span className="icon-[ant-design--audio-outlined] size-5 swap-on"></span>
            </label>
          </div>

          <div className="flex justify-center space-x-4">
            {!hasRecordingStarted ? (
              <button
                onClick={handleStartRecording}
                disabled={
                  status === "recording" ||
                  (!enableVideo && !enableAudio) ||
                  disabled
                }
                className="btn btn-gradient btn-success disabled:btn-secondary"
              >
                {t("start")}
              </button>
            ) : (
              <>
                <button
                  onClick={handleRetake}
                  disabled={disabled || isLoading}
                  className="btn btn-gradient btn-active"
                >
                  {t("retake")}
                </button>
                <button
                  onClick={handleStopRecording}
                  disabled={status !== "recording" || disabled || isLoading}
                  className="btn btn-gradient btn-info disabled:btn-secondary"
                >
                  {isLoading ? t("submitting") : t("submit")}
                </button>
              </>
            )}
          </div>
        </div>

        <div className="relative w-full h-[26rem] flex justify-center items-center bg-gray-900 rounded-lg overflow-hidden">
          {status === "recording" && (
            <div className="absolute z-70 top-3 right-3">
              <span className="relative flex size-5">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex size-5 rounded-full bg-red-500"></span>
              </span>
            </div>
          )}

          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div className="text-white text-lg">
                {isPractice ? t("practice_submitting") : t("submitting")}...
              </div>
            </div>
          )}

          {!mediaBlobUrl && enableVideo && (
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              className="w-full h-full object-cover"
            />
          )}

          {mediaBlobUrl &&
            (enableVideo ? (
              <video
                src={mediaBlobUrl}
                controls
                autoPlay
                loop
                className="w-full h-full object-cover"
              />
            ) : (
              <audio
                src={mediaBlobUrl}
                controls
                autoPlay
                loop
                className="w-full"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default AudioVideoAnswer;
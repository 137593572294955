import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { showNotification } from "../utils/functions";
import AssessmentHeader from "../components/AssessmentHeader";
import AssessmentBody from "../components/AssessmentBody";
import Danger from "../components/Danger";
import { getPercentage } from "../utils/functions";
import { usePractice } from "../context/PracticeSessionContext";
import Spinner from "../components/Spinner";

export default function Practice() {
  const [value, setValue] = useState<string>("");
  const valueRef = useRef<string>(""); // Stores the latest value
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useLanguage();
  const {
    assessmentObject,
    setCurrentIndex,
    currentIndex,
    isLastIndex,
    interviewQuestionsLength,
    setisPracticeCompleted,
  } = usePractice();

  // Memoize the current question to prevent unnecessary re-renders
  const question = useMemo(
    () => assessmentObject?.interview_questions?.[currentIndex],
    [assessmentObject, currentIndex]
  );

  // Calculate initial time left using useMemo
  const calculateTimeLeft = useCallback((): number => {
    return (
      (Number(question?.reading_time) ?? 0) +
      (Number(question?.time_duration) ?? 0)
    );
  }, [question]);

  const timeLeftInitial = useMemo(
    () => calculateTimeLeft(),
    [calculateTimeLeft]
  );
  const [timeLeft, setTimeLeft] = useState<number>(timeLeftInitial);

  const hasSubmittedRef = useRef<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  // Updates valueRef whenever value changes
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  // Memoized handleSubmit function to avoid unnecessary re-renders
  const handleSubmit = useCallback(async () => {
    if (isLoading || hasSubmittedRef.current) return;
    hasSubmittedRef.current = true;

    // Stop the timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    setIsLoading(true);
    let answer = valueRef.current.trim();
    try {
      if (answer) {
        showNotification(t("answer_submitted_successfully"), "success");
      } else {
        showNotification(t("you_have_left_an_empty_answer"), "warning");
      }
    } catch (error) {
      showNotification("An error occurred. Please try again.", "error");
    } finally {
      setValue("");
      valueRef.current = "";

      setTimeout(() => {
        if (!isLastIndex) {
          setCurrentIndex(currentIndex + 1);
        } else {
          setisPracticeCompleted();
          navigate(-1);
        }
        setIsLoading(false);
      }, 3000);
    }
  }, [isLoading, isLastIndex, currentIndex, navigate, setCurrentIndex]);

  // Effect to manage the timer
  useEffect(() => {
    // Reset time left and submission state when the question changes
    setTimeLeft(calculateTimeLeft());
    hasSubmittedRef.current = false;

    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    // Start a new timer
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current as NodeJS.Timeout);
          timerRef.current = null;

          if (!hasSubmittedRef.current) {
            handleSubmit(); // Auto-submit with latest input
          }
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    // Cleanup the timer on unmount or question change
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [question?._id, handleSubmit, calculateTimeLeft]);

  if (!assessmentObject?.consent) return <Danger />;

  return (
    <div className="w-full">
      <MemoizedAssessmentHeader
        question={question}
        currentIndex={currentIndex}
        questionsLength={interviewQuestionsLength}
        value={value}
        isLoading={isLoading}
        title={
          isLoading
            ? t("submitting")
            : isLastIndex
            ? t("submit")
            : t("submit_and_next")
        }
        percentage={`${getPercentage(
          currentIndex + 1,
          interviewQuestionsLength
        )}`}
        timeLeft={timeLeft}
        handleSubmit={handleSubmit}
        isRecording={false}
      />
      {isLoading ? (
        <div className="flex flex-col justify-center items-center mt-52">
          <Spinner />
          <h1 className="mt-2">{t("submitting")}</h1>
        </div>
      ) : (
          <MemoizedAssessmentBody
            timerRef={timerRef}
            isRecording={false}
            setIsRecording={()=>{}}
            
            isPractice={true}
            
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          assessmentObject={assessmentObject}
          currentIndex={currentIndex}
          value={value}
          setValue={setValue}
          timeLeft={timeLeft}
        />
      )}
    </div>
  );
}

// Memoize the components to avoid unnecessary re-renders
const MemoizedAssessmentHeader = React.memo(AssessmentHeader);
const MemoizedAssessmentBody = React.memo(AssessmentBody);

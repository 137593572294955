import { InterviewQuestion } from "../types/apiType";
import AudioVideoAnswer from "./Answers/AudioVideoAnswer";
import CodingAnswer from "./Answers/CodingAnswer";
import MCQAnswer from "./Answers/MCQAnswer";
import WritingAnswer from "./Answers/WritingAnswer";

export default function AssessmentAnswerBlock({
  question,
  value,
  setValue,
  timeLeft,
  setIsRecording,
  timerRef,
  setIsLoading,
  isLoading,
  isPractice
  
}: {
  question: InterviewQuestion | null;
  disabled?: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  timeLeft: number;
  isRecording: boolean;
  setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
  timerRef: any;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean,
    isPractice?: boolean;
}) {
  const NoAnswerFound = () => {
    return <h1>Sorry, I think you forget to add answer type.</h1>;
  };

  const isDisabled = timeLeft == 0;
  return (
    <div
      className={`w-full md:w-[60%] ${isDisabled ? "pointer-events-none" : ""}`}
    >
      {question?.question_type === "mcq" ? (
        <MCQAnswer
          disabled={isDisabled}
          value={value}
          setValue={setValue}
          question={question}
        />
      ) : question?.question_type === "audio/video" ? (
        <AudioVideoAnswer
          timeLeft={timeLeft}
 
          disabled={isDisabled}
          setValue={setValue}
            setIsRecord={setIsRecording}
            question={question}
            timerRef={timerRef}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            isPractice={isPractice}
        />
      ) : question?.question_type === "code" ? (
        <CodingAnswer disabled={isDisabled} value={value} setValue={setValue} />
      ) : question?.question_type === "writing" ? (
        <WritingAnswer
          disabled={isDisabled}
          value={value}
          setValue={setValue}
        />
      ) : (
        <NoAnswerFound />
      )}
    </div>
  );
}

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles

export default function WritingAnswer({
  setValue,
  value,
  disabled,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  disabled: boolean;
}) {
  return (
    <ReactQuill
      readOnly={disabled}
      className="text-white h-72"
      theme="snow"
      value={value}
      onChange={setValue}
    />
  );
}

import { useLanguage } from "../context/LanguageContext";
import { InterviewQuestion } from "../types/apiType";

export default function AssessmentQuestionBlock({
  question,
  timeLeft,
}: {
  question: InterviewQuestion | null;
  timeLeft?: number;
}) {
  const { t ,language } = useLanguage();
  return (
    <div className="w-full md:w-[40%]">
      <h1 className="text-2xl mb-2 font-600">{t("question_statement")}:</h1>
      {question?.title_en && (question?.title_ur === "" || question?.title_ur) && (
        <div
          className={`text-justify ${timeLeft ? "select-none " : ""}`}
          dangerouslySetInnerHTML={{ __html:language === 'en' ?  question.title_en : question.title_ur }}
        />
      )}
    </div>
  );
}

import axios from "axios";
import {  LANGUAGE_VERSIONS } from "../utils/constants";

const API = axios.create({
  baseURL: "https://emkc.org/api/v2/piston",
});



export const executeCode = async (
  language: string,
  sourceCode: string
): Promise<any> => {
  const response = await API.post<any>("/execute", {
    language,
    version: LANGUAGE_VERSIONS[language],
    files: [
      {
        content: sourceCode,
      },
    ],
  });
  return response.data;
};


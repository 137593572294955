import { useMemo } from "react";
import { AssessmentHeaderProps } from "../types/assessmentType";
import PercentageBox from "./PercentageBox";
export default function AssessmentHeader({
  handleSubmit,
  timeLeft,
  percentage = "10",
  title,
  questionsLength,
  currentIndex,
  isLoading,
  isRecording,
  question
}: AssessmentHeaderProps) {
  const timerClass = useMemo(() => {
    if (timeLeft === 0) return "badge-active";
    if (timeLeft <= 15) return "badge-error";
    if (timeLeft <= 60) return "badge-warning";
    return "badge-info";
  }, [timeLeft]);

  const formatTime = (seconds: number) =>
    `${Math.floor(seconds / 60)}:${seconds % 60 < 10 ? "0" : ""}${
      seconds % 60
    }`;

  return (
    <div className="w-full">
      <PercentageBox percentage={percentage} />
      <div className="py-2 px-4 flex items-center justify-between bg-slate-900">
        <span
          className={`${
            isLoading ? "invisible" : ""
          } badge badge-xl badge-soft ${timerClass} py-5 px-6`}
        >
          <span className="icon-[lets-icons--clock-duotone] size-7"></span>
          {formatTime(timeLeft)}
        </span>

        <span className="hidden sm:block">
          {currentIndex + 1}/{questionsLength}
        </span>
        <button
          disabled={timeLeft === 0 || isRecording}
          onClick={handleSubmit}
          className={`${question?.question_type === "audio/video" ? "invisible" :"visible"} btn btn-info btn-gradient disabled:btn-disabled`}
        >
          {title}
        </button>
      </div>
    </div>
  );
}

import { useLanguage } from "../../context/LanguageContext";
import { InterviewQuestion } from "../../types/apiType";

export default function MCQAnswer({
  question,
  setValue,
  value,
  disabled,
}: {
  question: InterviewQuestion | null;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  value: string;
  disabled: boolean;
}) {
  const { language } = useLanguage();
  return (
    <div className="gap-2 flex-col flex">
      {question?.answers?.map((option, index) => (
        <button
          disabled={disabled}
          key={index}
          className={`flex gap-x-4 justify-between items-center border relative p-4 cursor-pointer ${
            value === index.toString()
              ? "alert alert-soft alert-success"
              : "alert alert-soft "
          }`}
          onClick={() => setValue(`${index}`)} // Update selected option
        >
          <h1 className="text-1xl text-left ">
            {language === "en" ? option?.en : option?.ur}
          </h1>
          <h1
            className={`${
              value === index.toString() ? "text-success" : "text-secondary/80"
            }  text-5xl`}
          >
            {language == 'en' ?  String.fromCharCode(65 + index):String.fromCharCode(1575 + index) }
          </h1>
        </button>
      ))}
    </div>
  );
}

import { Notyf } from "notyf";
// Function to extract YouTube Video ID from a URL
export const getYouTubeVideoId = (url: string): string => {
  const regExp =
    /(?:youtube\.com\/(?:[^/]+\/[^/]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/;
  const match = url.match(regExp);
  return match ? match[1] : "";
};

// Function to format time in hh:mm:ss format
export const formatTime = (time: string | number): string => {
  const seconds = Number(time);
  if (isNaN(seconds)) return "Invalid time";

  if (seconds < 60) {
    return `${seconds} sec`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return remainingSeconds
      ? `${minutes} min ${remainingSeconds} sec`
      : `${minutes} min`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return [
      hours ? `${hours} hr` : "",
      minutes ? `${minutes} min` : "",
      remainingSeconds ? `${remainingSeconds} sec` : "",
    ]
      .filter(Boolean)
      .join(" ");
  }
};

// Create a singleton Notyf instance with custom types
const notyf = new Notyf({
  duration: 3000,
  ripple: true,
  position: { x: "left", y: "bottom" },
  dismissible: true,
  types: [
    {
      type: "info",
      background: "#06B6D4",
      icon: {
        className: "icon-[tabler--info-circle] !text-info",
        tagName: "i",
      },
      
    },
    {
      type: "warning",
      background: "#FCAA1D",
      icon: {
        className: "icon-[tabler--alert-triangle] !text-warning",
        tagName: "i",
      },
   
    },
  ],
});

// Allowed notification types
type NotificationType = "success" | "error" | "info" | "warning";

/**
 * Show a global notification
 * @param {string} message - Notification message
 * @param {NotificationType} type - Notification type (success, error, info, warning)
 */
export function showNotification(message: string, type: NotificationType = "success"): void {
  if (type === "success") {
    notyf.success(message);
  } else if (type === "error") {
    notyf.error(message);
  } else {
    notyf.open({ type, message });
  }
}


export const getPercentage = (currentLength:number, totalLength:number) => {
  if (totalLength === 0) return 0; // Avoid division by zero
  return (currentLength / totalLength) * 100;
};

import { useState } from "react";
import Danger from "../components/Danger";
import { useApi } from "../context/ApiContext";
import { useScreenshot } from "../context/ScreenshotContext";
function Gallery() {
  const { screenshots } = useScreenshot();
  const [active, setActive] = useState(screenshots?.[0]?.src);
  const { assessmentObject } = useApi();
  if (!assessmentObject?.consent) return <Danger />;
  return (
    <div className="grid gap-5">
      <div className="relative">
        <img
          className="h-auto  w-full max-w-full rounded-lg object-contain object-center md:h-[480px]"
          src={active}
          alt="Selected"
        />
      </div>
      <div className={`flex flex-wrap justify-center gap-4 overflow-x-auto`}>
        {screenshots?.map(({ src }, index) => (
          <div
            className={`${active == src ? "border border-success" : ""} `}
            key={index}
          >
            <img
              onClick={() => setActive(src)}
              src={src}
              className="h-20  max-w-full cursor-pointer rounded-lg object-cover object-center"
              alt={`gallery-image-${index+1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;

import { useEffect, useState } from "react";
import { formatTime, getYouTubeVideoId } from "../utils/functions";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { useApi } from "../context/ApiContext";
import _ from "lodash";
import { usePractice } from "../context/PracticeSessionContext";

export default function Main() {
  const { assessmentObject } = useApi();

  const navigate = useNavigate();
  const { t, setLanguage } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { updateConsents } = useApi();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setStep(1);
  };

  const handleAgree = () => {
    setIsLoading(true);
    setTimeout(() => {
      updateConsents();
      setIsLoading(false);
      navigate("/permissions", { replace: true });
      closeModal();
    }, 2000);
  };

  useEffect(() => {
    if (assessmentObject?.assessment_type !== "candidate_personality") {
      setLanguage("en");
    }
  }, [assessmentObject?.assessment_type]);
  if (!assessmentObject)
    return (
      <div className="flex flex-col items-center justify-center gap-4 mt-52">
        <span className="icon-[fxemoji--emptypage] text-7xl"></span>
        <h1>{t("no_data_found")}</h1>
      </div>
    );

  return (
    <div className="text-center text-xl font-semibold pt-5 mx-5">
      <div className="flex flex-col gap-6 justify-between">
        <WelcomeMessage
          text={assessmentObject.welcome_note ?? t("welcome_note")}
        />

        <div className="flex flex-col sm:flex-row gap-4">
          <VideoPlayer
            videoUrl={
              assessmentObject?.intro_video ??
              "https://www.youtube.com/embed/igKWXQazp2g?si=3MvIqYVaFEHqJXx1"
            }
          />
          <AssessmentDetails
            assessment_type={_.startCase(assessmentObject.assessment_type)}
            title={assessmentObject?.title}
            duration={assessmentObject?.time_duration}
            openModal={openModal}
          />
        </div>
      </div>

      {isModalOpen && (
        <ConsentModal
          step={step}
          setStep={setStep}
          closeModal={closeModal}
          handleAgree={handleAgree}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

const WelcomeMessage = ({ text }: { text: string }) => {
  const { t } = useLanguage();
  return (
    <div>
      <h1 className="text-base-content sm:text-2xl text-sm font-sans">
        👋 {t("welcome")}
      </h1>
      <h3 className="text-sm sm:text-md sm:px-28">{text}</h3>
    </div>
  );
};

const VideoPlayer = ({ videoUrl }: { videoUrl?: string }) => {
  const { t } = useLanguage();
  if (!videoUrl) return <p>{t("no_video_available")}</p>;

  return videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be") ? (
    <iframe
      className="w-full sm:w-1/2 hidden sm:block h-[30rem] self-center bg-base-700"
      src={`https://www.youtube.com/embed/${getYouTubeVideoId(videoUrl)}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  ) : (
    <video
      src={videoUrl}
      className="w-52 h-52 bg-base-700"
      controls
      autoPlay
      muted
    />
  );
};

const AssessmentDetails = ({
  title,
  duration,
  openModal,
  assessment_type,
}: {
  title?: string;
  duration?: string;
  openModal: () => void;
  assessment_type?: string;
}) => {
  const { t } = useLanguage();

  return (
    <div className="w-full sm:w-1/2 justify-between relative ">
      <div className="border-base-content/25 w-full overflow-x-auto border">
        <table className="table">
          <caption className="text-lg font-bold mb-2 bg-slate-800 text-white py-3">
            {title ??
              _.startCase(
                assessment_type === "Candidate Personality"
                  ? "Personality Assessment"
                  : assessment_type
              )}
          </caption>
          {/* <thead>
            <tr >
             <th>{t("name")}</th>
              <td className="text-nowrap">Muhammad Usama</td>
            </tr>
          </thead> */}
          <tbody className="block sm:table-row-group">
            {/* Row 1: Total Duration */}
            <tr className="block sm:table-row  ">
              <th className="block sm:table-cell text-center font-semibold p-2 sm:p-3">
                {t("total_duration")}
              </th>
              <td className="block sm:table-cell text-center p-2 sm:p-3">
                {duration ? formatTime(duration) : formatTime(1200)}
              </td>
            </tr>

            {/* Row 2: Assessment Type */}
            <tr className="block sm:table-row  ">
              <th className="block sm:table-cell text-center font-semibold p-2 sm:p-3">
                {t("assessment_type")}
              </th>
              <td className="block sm:table-cell text-center p-2 sm:p-3">
                <span className="badge badge-outline badge-accent">
                  {assessment_type
                    ? "Multiple choice & video responses"
                    : "Job specific assessment"}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <StartInterviewButton openModal={openModal} />
    </div>
  );
};

const StartInterviewButton = ({ openModal }: { openModal: () => void }) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { updatePracticeConsents, assessmentObject: practiceAssessmentObject } =
    usePractice();
  return (
    <div className="flex items-center justify-between">
      {!practiceAssessmentObject?.isCompleted ? (
        <button
          type="button"
          className=" btn btn-lg px-10 py-2 btn-outline btn-soft btn-info mt-2 self-end sm:absolute right-0 bottom-0"
          onClick={() => {
            updatePracticeConsents();
            navigate("/practice");
          }}
        >
          {t("start_practice")}
        </button>
      ) : (
        <button
          disabled={!practiceAssessmentObject?.isCompleted}
          type="button"
          className=" btn btn-lg px-10 py-2 btn-gradient btn-accent mt-2 self-end sm:absolute right-0 bottom-0"
          onClick={openModal}
        >
          {t("start_assessment")}
        </button>
      )}
    </div>
  );
};

const ConsentModal = ({
  step,
  setStep,
  closeModal,
  handleAgree,
  isLoading,
}: {
  step: number;
  setStep: (step: number) => void;
  closeModal: () => void;
  handleAgree: () => void;
  isLoading: boolean;
}) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black/40">
    <div className="bg-white p-8 rounded-lg max-w-xl  shadow-lg animate-modal-in">
      {step === 1 ? (
        <StepOne setStep={setStep} />
      ) : (
        <StepTwo
          closeModal={closeModal}
          handleAgree={handleAgree}
          isLoading={isLoading}
        />
      )}
    </div>
  </div>
);

const StepOne = ({ setStep }: { setStep: (step: number) => void }) => {
  const { t } = useLanguage();
  return (
    <>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        {t("instructions")}
      </h2>
      <p className="text-gray-600 text-left mb-4 text-sm">
        {t("first_consent_a")}
      </p>
      <p className="text-gray-600 text-left mb-4 text-sm">
        {t("first_consent_b")}
      </p>
      <p className="text-gray-600 text-left mb-4 text-sm">
        {t("first_consent_c")}
      </p>
      <p className="text-gray-600 text-left mb-6 text-sm">
        {t("first_consent_d")}
      </p>
      <div className="flex justify-end">
        <button
          onClick={() => setStep(2)}
          className="btn btn-accent btn-gradient btn-lg"
        >
          {t("continue")}
        </button>
      </div>
    </>
  );
};

const StepTwo = ({
  closeModal,
  handleAgree,
  isLoading,
}: {
  closeModal: () => void;
  handleAgree: () => void;
  isLoading: boolean;
}) => {
  const { t } = useLanguage();
  return (
    <>
      <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-4">
        {t("instructions")}
      </h2>
      <p className="text-gray-600 text-left mb-4 text-xs sm:text-sm">
        {t("second_consent_a")}
      </p>
      <p className="text-gray-600 text-left mb-4 text-xs sm:text-sm">
        <strong>Data Retention Consent: </strong>
        {t("second_consent_b")}
      </p>
      <p className="text-gray-600 text-left mb-4 text-xs sm:text-sm">
        <strong>Data Sharing Consent:</strong> {t("second_consent_c")}
      </p>
      <p className="text-gray-600 text-left mb-6 text-xs sm:text-sm">
        <strong>AI Analysis Consent:</strong> {t("second_consent_d")}
      </p>
      <div className="flex flex-col md:flex-row md:justify-end  gap-2">
        <button
          disabled={isLoading}
          onClick={closeModal}
          className="btn  btn-secondary btn-gradient btn-lg w-full sm:w-auto"
        >
          {t("cancel")}
        </button>
        <button
          onClick={handleAgree}
          className="btn btn-primary btn-accent btn-lg w-full sm:w-auto"
          disabled={isLoading}
        >
          {isLoading ? (
             t("loading")
          ) : (
            t("agree")
          )}
        </button>
      </div>
    </>
  );
};

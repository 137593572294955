import { Link } from "react-router-dom";
import LanguageToggle from "./LanguageToggle";
// import ThemeToggle from "./ThemeToggle";
import Logo from "../assets/w_logo.png";
import { useApi } from "../context/ApiContext";
export default function Navbar() {
  const { assessmentObject } = useApi();
  return (
    <nav className="navbar fixed  top-0 left-0 w-full z-50 shadow  h-14 bg-[#303743]">
      <div className="navbar-start">
        <Link
          className="link text-base-content/90 link-neutral text-xl font-semibold no-underline"
          to="/"
        >
          <img className="h-20 w-34 " src={Logo} alt="Airec.io" />
        </Link>
      </div>

      {assessmentObject?.assessment_type === "candidate_personality" && (
        <div className="navbar-end items-center gap-4">
          <div className="dropdown relative gap-5 inline-flex rtl:[--placement:bottom-end]">
            {/* <ThemeToggle /> */}

            <LanguageToggle />
            <button
              id="dropdown-default"
              type="button"
              className="dropdown-toggle hidden btn btn-primary btn-outline max-sm:btn-square"
              aria-haspopup="menu"
              aria-expanded="false"
              aria-label="Dropdown"
            >
              <span className="max-sm:hidden">Theme</span>
              <span className="icon-[tabler--aperture] block size-6 sm:hidden"></span>
              <span className="icon-[tabler--chevron-down] dropdown-open:rotate-180 size-5 max-sm:hidden"></span>
            </button>
            <ul
              className="dropdown-menu dropdown-open:opacity-100 hidden min-w-40"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-default"
            >
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Default"
                  value="light"
                />
              </li>
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Dark"
                  value="dark"
                />
              </li>
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Gourmet"
                  value="gourmet"
                />
              </li>
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Corporate"
                  value="corporate"
                />
              </li>
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Luxury"
                  value="luxury"
                />
              </li>
              <li>
                <input
                  type="radio"
                  name="theme-dropdown"
                  className="theme-controller btn btn-text w-full justify-start"
                  aria-label="Soft"
                  value="soft"
                />
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

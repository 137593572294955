import { LANGUAGE_VERSIONS } from "../utils/constants";

const languages = Object.entries(LANGUAGE_VERSIONS);

const LanguageSelector = ({ language, onSelect,disabled }: { language: string; onSelect: (lang: string) => void ,disabled:boolean}) => {
  return (
    <div className="m-2">
      <select disabled={disabled} className="select max-w-sm appearance-none" aria-label="select" value={language} onChange={(e) => onSelect(e.target.value)}>
        {languages.map(([lang, version]) => (
          <option key={lang} value={lang}>
            {lang} ({version})
          </option>
        ))}
      </select>
    
    </div>
  );
};

export default LanguageSelector;
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import "./index.css";
import * as Sentry from "@sentry/react";
// Initialize Sentry
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: ["error", "warn", "log", "info"], // Capture console logs, errors, warnings, and info
    }),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "system",
      enableScreenshot: false,
      isNameRequired: true,
      isEmailRequired: true,
    }),
  ],
  tracesSampleRate: 1.0, // Adjust as needed
});

// Wrap App with Sentry's Error Boundary
const SentryWrappedApp = Sentry.withErrorBoundary(App, {
  fallback: (
    <div className="flex h-screen justify-center items-center">
      <p>Something went wrong. Please try to reload.</p>
    </div>
  ), // Custom fallback UI
});

createRoot(document.getElementById("root")!).render(
  // <StrictMode>
  <BrowserRouter basename="/">
    <SentryWrappedApp />
  </BrowserRouter>
  // </StrictMode>
);

import AssessmentAnswerBlock from "./AssessmentAnswerBlock";
import AssessmentQuestionBlock from "./AssessmentQuestionBlock";

export default function AssessmentBody({
  assessmentObject,
  currentIndex,
  timeLeft,
  value,
  setValue,
  isRecording,
  setIsRecording,
  timerRef,
  setIsLoading,
  isLoading,
  isPractice
}: {
  assessmentObject: any;
  currentIndex: number;
  timeLeft: number;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isRecording: boolean;
    setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
    timerRef: any;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    isPractice?: boolean;
}) {
  return (
    <div className="flex flex-col md:flex-row gap-10 p-4">
      <AssessmentQuestionBlock
        timeLeft={timeLeft}
        question={assessmentObject?.interview_questions?.[currentIndex] ?? null}
      />
      <AssessmentAnswerBlock
   
        value={value}
        setValue={setValue}
        timeLeft={timeLeft}
        question={assessmentObject?.interview_questions?.[currentIndex] ?? null}
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        timerRef={timerRef}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isPractice={isPractice}
      />
    </div>
  );
}

import { useState, MutableRefObject } from "react";
import { showNotification } from "../utils/functions";
import { executeCode } from "../api/runCodeApi";
import { useLanguage } from "../context/LanguageContext";
import * as Sentry from "@sentry/react";
interface OutputProps {
  editorRef: MutableRefObject<any>;
  language: string;
}

const Output: React.FC<OutputProps> = ({ editorRef, language }) => {
  const { t } = useLanguage();
  const [output, setOutput] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const runCode = async () => {
    const sourceCode = editorRef.current?.getValue();
    if (!sourceCode) {
      Sentry.captureException(t("give_some_code_run_error"));
      showNotification(t("give_some_code_run_error"), "error");
    }

    try {
      setIsLoading(true);
      const { run: result } = await executeCode(language, sourceCode);
      setOutput(result.output?.split("\n") || []);
      setIsError(!!result.stderr);
    } catch (error: any) {
      Sentry.captureException(error);
      showNotification(error.message || t("unable_to_run_code"), "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-[50vh] ">
      <button
        disabled={!editorRef.current?.getValue()}
        className="btn btn-gradient btn-success my-2"
        onClick={runCode}
      >
        {isLoading ? t("loading") : t("run_code")}
      </button>
      <div
        className={`h-72 p-2 border ${isError ? "text-error border-error" : ""}`}
      >
        {output
          ? output?.map((line, i) => <h1 key={i}>{line}</h1>)
          : t("click_to_run_code")}
      </div>
    </div>
  );
};

export default Output;
